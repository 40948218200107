import React from "react";
import SEO from "../components/seo";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import "../css/ajuda.css";

function AjudaPage() {
    return (
        <>
            <SEO title="Ajuda" />
            <section className="pt-8 pb-32 px-12 lg:py-16 lg:px-6">
                <div className="container mx-auto text-azul_base ">
                    <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl lg:mb-6">Ajuda</h1>
                    <div className="max-w-2xl">
                        <p className="text-lg text-cinza_default mb-12">
                            Se não puder tirar suas dúvidas no nosso FAQ, envie um e-mail através do nosso formulário ou
                            entre em contato pelo telefone (11)5585-9945
                        </p>
                        <Collapse accordion={true}>
                            <Panel
                                extra={<i className="Chevron"></i>}
                                showArrow={false}
                                header="Pergunta? Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                                <p className="text-vermelho lg:text-lg">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                            </Panel>
                            <Panel
                                extra={<i className="Chevron"></i>}
                                showArrow={false}
                                header="Pergunta? Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                                <p className="text-vermelho lg:text-lg">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                            </Panel>
                            <Panel
                                extra={<i className="Chevron"></i>}
                                showArrow={false}
                                header="Pergunta? Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                                <p className="text-vermelho lg:text-lg">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                            </Panel>
                            <Panel
                                extra={<i className="Chevron"></i>}
                                showArrow={false}
                                header="Pergunta? Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                                <p className="text-vermelho lg:text-lg">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                            </Panel>
                            <Panel
                                extra={<i className="Chevron"></i>}
                                showArrow={false}
                                header="Pergunta? Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                                <p className="text-vermelho lg:text-lg">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                            </Panel>
                            <Panel
                                extra={<i className="Chevron"></i>}
                                showArrow={false}
                                header="Pergunta? Lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                                <p className="text-vermelho lg:text-lg">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AjudaPage;
